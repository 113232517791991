import {Logo} from './index'


function NewProductsTagLine() {
  return (
    <div className="NewProductsTagLine">


    </div>
  );
}

export default NewProductsTagLine;
